import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import Layout from "../../../components/layout";
import Container from "../../../common/components/container";
import queryString from "query-string";
import { Location } from "@reach/router";

import { listUsersFunction } from "../../../graphql/queries";

const EditUser = () => {
  const dispatch = useDispatch();
  const [loading, update_loading] = useState(true);
  const [query_string, update_query_string] = useState("");
  const [user_details, update_user_details] = useState("loading");
  const [user_groups, update_user_groups] = useState("loading");
  useEffect(() => {
    // update_loading(false);
  }, []);

  function fetchUserDetails(search) {
    update_query_string(search);
    console.log("search.id");
    console.log(search.id);
    listCognitoUsers("get_user", { Username: search.id });
    listCognitoUsers("list_groups_for_user", { Username: search.id });
    update_loading(false);
  }

  async function listCognitoUsers(type, values) {
    console.log("type");
    console.log(type);

    console.log("values");
    console.log(values);

    try {
      switch (type) {
        case "get_user":
          console.log("trying to add user");
          try {
            const result = await API.graphql(
              graphqlOperation(listUsersFunction, {
                type: "get_user",
                values: JSON.stringify(values),
              })
            );
            const parsed_results = JSON.parse(result.data.listUsersFunction);
            console.log(parsed_results);
            update_user_details(parsed_results.body);
          } catch (error) {
            console.log(error);
          }
          break;
        case "list_groups_for_user":
          console.log("trying to add user");
          try {
            const result = await API.graphql(
              graphqlOperation(listUsersFunction, {
                type: "list_groups_for_user",
                values: JSON.stringify(values),
              })
            );
            const parsed_results = JSON.parse(result.data.listUsersFunction);
            console.log(parsed_results);
            update_user_groups(parsed_results.body[0].Groups);
          } catch (error) {
            console.log(error);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }

  function returnForm(location, navigate) {
    if (loading) {
      const search = location.search ? queryString.parse(location.search) : {};
      fetchUserDetails(search);
      return <div>Loading...</div>;
    }

    return (
      <div>
        {/* <Edit location={location} navigate={navigate} search={search} /> */}
        <div>User ID: {query_string.id}</div>
        <div>{returnUserDetails()}</div>
        <div>{returnUserGroups()}</div>
      </div>
    );
  }

  function returnUserDetails() {
    if (user_details === "loading") {
      return <div>Loading....</div>;
    }
    return (
      <div>
        {/* <div>{JSON.stringify(user_details)}</div> */}
        <div>
          Email:
          {_.find(user_details[0].UserAttributes, { Name: "email" }).Value}
        </div>
        <div>
          User Created:
          {JSON.stringify(user_details[0].UserCreateDate)}
        </div>
        <div>
          Last Modified:
          {JSON.stringify(user_details[0].UserLastModifiedDate)}
        </div>
        <div>
          Enabled:
          {JSON.stringify(user_details[0].Enabled)}
        </div>
        <div>
          Confirmed:
          {JSON.stringify(user_details[0].UserStatus)}
        </div>
      </div>
    );
  }

  function returnUserGroups() {
    if (user_groups === "loading") {
      return <div>Loading....</div>;
    }
    return (
      <div>
        <ul>
          {user_groups.map((group) => {
            return <li>{group.GroupName}</li>;
          })}
        </ul>
      </div>
    );
  }

  return (
    <Layout>
      <Container>
        <div>
          <h1>Edit User</h1>
        </div>
        <Location>
          {({ location, navigate }) => returnForm(location, navigate)}
        </Location>
      </Container>
    </Layout>
  );
};

export default EditUser;
